import { AskableResources, LANGUAGES, LOCALES } from '@askableorg/i18n';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import enClients from './namespaces/clients.json';
import enComponents from './namespaces/components.json';
import enDocbuilder from './namespaces/docbuilder.json';
import enSessions from './namespaces/sessions.json';

export const DEFAULT_RESOURCES = {
  en: {
    sessions: enSessions,
    clients: enClients,
    components: enComponents,
    docbuilder: enDocbuilder,
  },
};

export type EN_RESOURCES = (typeof DEFAULT_RESOURCES)['en'];

export * from './resources';

const i18n = i18next.use(initReactI18next).use(AskableResources);

export { i18n, LANGUAGES, LOCALES };
