import { Button } from '@askable/ui/core/button';
import { Plus } from 'lucide-react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormErrorMessage } from 'components/Form/FormErrorMessage';
import { type RecruitFormFields } from 'containers/Studies/data/schemas/recruitSchema';
import { Locale } from 'generated/graphql';

import { LanguagesItem } from './LanguagesItem';

export type LanguagesType = NonNullable<RecruitFormFields['languages']>[number];

const FIELD_NAME = 'languages';

export const Languages = ({ isDisabled }: { isDisabled?: boolean }) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<RecruitFormFields>();
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name: FIELD_NAME,
  });
  const formValues = useWatch({ control, name: FIELD_NAME });

  /* rhett/ask-10935-remove-temp-locale-filtering */
  const languages = Object.values(Locale).filter(locale => !['en_US', 'en_GB', 'en_AU'].includes(locale));
  const selectedLanguages = formValues?.map(item => item.locale) ?? [];
  const isAddDisabled = isDisabled || selectedLanguages.length >= languages.length;

  const addListItem = () => {
    const newItem: LanguagesType = {
      locale: undefined,
      fluency: [],
    };

    setValue('languages', [...(formValues ?? []), newItem], {
      shouldValidate: false,
    });
  };

  const handleRemove = (itemIndex: number) => {
    const updatedList = formValues?.filter((_, index) => index !== itemIndex);
    field.onChange(updatedList);
  };

  return (
    <div className="flex flex-col gap-3">
      {field.value?.map((value, index: number) => (
        <LanguagesItem
          index={index}
          isDisabled={isDisabled}
          items={languages}
          key={index.toString() + value.locale}
          selectedLanguages={selectedLanguages}
          onRemove={handleRemove}
        />
      ))}

      {error ? <FormErrorMessage message={error.message} prefix="sections.studies.formValidation" /> : null}

      <Button variant="outline" onClick={addListItem} disabled={isAddDisabled}>
        <Plus className="h-4 w-4" /> {t('sections.studies.recruit.fields.languagesCta')}
      </Button>
    </div>
  );
};
