import { resources, i18n } from '@askable/i18n';
import { LANGUAGES, LOCALES } from '@askableorg/i18n';
import { initReactI18next } from 'react-i18next';

const initialLang =
  typeof window !== 'undefined' ? (window.localStorage.getItem('lang') ?? navigator.language ?? 'en') : 'en';

i18n.use(initReactI18next).init({
  lng: initialLang,
  ns: ['clients', 'common', 'zod', 'components'],
  resources,
});

const translationDefault = 'en';

export default i18n;
export { LANGUAGES, LOCALES, translationDefault };
