import { Button } from '@askable/ui/core/button';
import { Checkbox } from '@askable/ui/core/checkbox';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@askable/ui/core/dropdown-menu';
import { Label } from '@askable/ui/core/label';
import { Plus, Trash } from 'lucide-react';
import { capitalize } from 'radash';
import { useTranslation } from 'react-i18next';

import { Locale, LocaleProficiency } from 'generated/graphql';

import type { Booking } from 'generated/graphql';

export const MetaIdentityLocalesComp = ({
  metaIdentityLocales,
  updateCriteria,
  removeCriteria,
}: {
  metaIdentityLocales: NonNullable<NonNullable<Booking['config']>['criteria']>['meta_identity_locales'];
  updateCriteria: (
    criteriaParent: string,
    field: string,
    value: LocaleProficiency[],
    operator: 'in',
    keepOtherValues?: boolean,
    locale?: Locale,
  ) => void;
  removeCriteria: (criteriaParent: string, field: string, value?: LocaleProficiency[]) => void;
}) => {
  const [t] = useTranslation();

  return (
    <div className="flex flex-col gap-6">
      {metaIdentityLocales?.map(metaIdentityLocal => {
        const locale = metaIdentityLocal?.locale;
        if (!locale) {
          return null;
        }
        return (
          <div key={metaIdentityLocal?.field}>
            <div className="flex justify-between">
              <p>{t(`global.locale.${locale}`)}</p>
              <Button
                variant="ghost"
                size="icon"
                disabled={metaIdentityLocales.length === 1}
                onClick={() => {
                  if (metaIdentityLocal?.field) {
                    removeCriteria('meta_identity_locales', metaIdentityLocal.field, metaIdentityLocal?.value);
                  }
                }}
              >
                <Trash className="h-4 w-4" />
              </Button>
            </div>
            {Object.values(LocaleProficiency).map(localeProficiency => {
              const htmlId = `${metaIdentityLocal?.field}-${localeProficiency}`;
              return (
                <div key={htmlId} className="flex items-center gap-2 py-1">
                  <Checkbox
                    id={htmlId}
                    checked={metaIdentityLocal?.value?.includes(localeProficiency)}
                    onCheckedChange={(checked: boolean) => {
                      updateCriteria(
                        'meta_identity_locales',
                        `user.meta.identity.locales.${locale}`,
                        checked
                          ? Array.from(new Set([...(metaIdentityLocal?.value || []), localeProficiency]))
                          : Array.from(new Set((metaIdentityLocal?.value || []).filter(v => v !== localeProficiency))),
                        'in',
                        true,
                        locale,
                      );
                    }}
                  />
                  <Label htmlFor={htmlId}>{capitalize(localeProficiency)}</Label>
                </div>
              );
            })}
          </div>
        );
      })}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="secondary">
            <Plus className="h-4 w-4" /> {t('sections.booking.setup.demographic_filters.locales.addLanguage')}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {/* rhett/ask-10935-remove-temp-locale-filtering  */}
          {Object.values(Locale)
            .filter(locale => !['en_US', 'en_GB', 'en_AU'].includes(locale))
            .map(locale => (
              <DropdownMenuItem
                disabled={metaIdentityLocales?.some(metaIdentityLocale => {
                  return metaIdentityLocale?.locale === locale;
                })}
                onClick={() => {
                  updateCriteria(
                    'meta_identity_locales',
                    `user.meta.identity.locales.${locale}`,
                    [LocaleProficiency.Native, LocaleProficiency.Fluent, LocaleProficiency.Conversational],
                    'in',
                    true,
                    locale,
                  );
                }}
                key={locale}
              >
                {t(`global.locale.${locale}`)}
              </DropdownMenuItem>
            ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
